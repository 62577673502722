@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: 'Nunito Sans', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .uppercase {
    font-family: 'Work Sans', sans-serif !important;
  }
  
  